import React, { useContext, Fragment } from "react"
import { graphql } from "gatsby"

/* Import Global Context(s) */
import ProjectsContext from "~context/projects"

/* Import Global Components */
import SEO from "~components/seo"
import Page from "~components/page"
import Filters from "~components/filters/filters"
import ProjectsTable from "~components/projectsTable/projectsTable"

/* Import Local Style(s) */
import "./partOf.css"

const Projects = ({ data }) => {
  const [projects] = useContext(ProjectsContext)
  const selected = data.sanityPartOf.title
  const description = data.sanityPartOf._rawDescription || null
  const options = data.allSanityPartOf.nodes

  const filteredProjects = projects.filter(project => {
    if (project.partOf.length === 0 || project.displayOnSite === false)
      return false
    let found = false
    for (var i = 0; i < project.partOf.length; i++) {
      if (project.partOf[i].title === selected) {
        found = true
        break
      }
    }
    return found
  })

  return (
    <Fragment>
      <SEO title="Part Of" />
      <Page title="projects">
        {/* keyword */}
        <Filters
          type="part of"
          selected={selected}
          options={options}
          description={description}
        />
        {/* keywords */}
        <ProjectsTable projects={filteredProjects} />
      </Page>
    </Fragment>
  )
}

export default Projects

export const query = graphql`
  query($id: String!) {
    sanityPartOf(id: { eq: $id }) {
      id
      title
      _rawDescription
    }
    allSanityPartOf {
      nodes {
        id
        title
      }
    }
  }
`
